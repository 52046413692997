import { useRouter } from 'next/router'
import React, { useContext } from 'react'
import { PrivilegesContext } from '../application/user'
import { schemeNameToSlug } from '../utils/getCurrentSlug'

const IndexPage: React.FC = () => {
  const { mappedPrivileges } = useContext(PrivilegesContext)
  const router = useRouter()
  const initialSlug = schemeNameToSlug(mappedPrivileges.canGetSchemes[0].name)

  if (router.asPath === '/' && router.pathname === '/') {
    if (mappedPrivileges.canGetSchemes.length > 0) {
      // TODO change to /fund-managers with TUM-5108
      router.replace(`${initialSlug}/investments`)
    } else {
      router.replace(`${initialSlug}/profile`)
    }
  } else {
    router.replace(router.asPath)
  }

  return <></>
}

export default IndexPage
